.page {
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  display: block;
}

.pageForeground {
  background-color: #ffffff;
}

.pageNavPlaceholder {
  width: 100%;
  height: 58px;
}

.content {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 58px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentNoNav {
  height: 100vh;
}
