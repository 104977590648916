.wrapper {
  display: flex;
  height: 100px;
  width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
  position: fixed;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  z-index: 2;
}

.itemsWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.pagesWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.loginWrapper {
  margin-right: 20px;
  height: 100%;
}

.hamburgerWrapper {
  margin-right: 20px;
}

.hamburgerMenuWrapper {
  display: block;
  background-color: #ffffff;
  position: fixed;
  top: 100px;
  bottom: 0;
  right: 0;
  left: 0;
}

.closed {
  display: none;
}
