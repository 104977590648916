.wrapper {
  box-sizing: border-box;
  padding: 4px 5px 0;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 4px solid #ffffff;
}

.button {
  box-sizing: border-box;
  background-color: #ffffff;
  border: none;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  color: #292f36;
  height: 50%;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 40px;
  outline: none;
  font-family: 'Mulish', sans-serif;
}

.button:hover {
  background-image: linear-gradient(to top left, #ff6b6b, #ba2063);
  color: #ffffff;
}

.active {
  /* @SOURCE: https://www.digitalocean.com/community/tutorials/css-gradient-borders-pure-css */
  border-image: linear-gradient(to top left, #ff6b6b, #ba2063) 1;
  border-bottom: 4px solid;
}

.mobileWrapper {
  height: 50px;
  display: flex;
  align-content: center;
  border-left: 4px solid #ffffff;
}

.mobileActive {
  border-image: linear-gradient(to top left, #ff6b6b, #ba2063) 1;
  border-left: 4px solid;
}

.mobileButton {
  font-family: 'Mulish', sans-serif;
  text-transform: uppercase;
  color: #292f36;
  font-size: 17px;
  width: 100%;
  text-align: left;
  padding-left: 20px;
  font-weight: 600;
  outline: none;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
