.wrapper {
  padding: 20px;
  width: 199px;
  cursor: pointer;
}

.logo {
  max-height: 100%;
  max-width: 100%;
}
