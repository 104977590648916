.wrapper {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.line {
  height: 4px;
  background-color: #292f36;
  display: block;
  margin-bottom: 6px;
  transition: all 0.3s ease-in-out;
}

.line:last-child {
  margin-bottom: 0;
}

.open .line:nth-child(2) {
  opacity: 0;
}

.open .line:first-child {
  transform: translateY(10px) rotate(45deg);
}

.open .line:last-child {
  transform: translateY(-10px) rotate(-45deg);
}
