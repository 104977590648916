.wrapper {
  position: absolute;
  width: 100vw;
  z-index: 1;
  margin-top: -4px;
  background-color: #fdc7c7;
  height: 4px;
  overflow: hidden;
  animation: start 0.4s ease-in;
}

.wrapperNoNav {
  margin-top: 0;
}

@keyframes start {
  0% {
    height: 0;
    opacity: 0;
  }

  50% {
    height: 0;
    opacity: 0;
  }

  100% {
    height: 4px;
    opacity: 1;
  }
}

.bar {
  position: absolute;
  background-color: #ff6b6b;
  top: 0;
  bottom: 0;
  left: -100%;
  width: 100%;
}

.bar1 {
  animation: moveBar 2.5s infinite;
  animation-delay: 0.5s;
}

.bar2 {
  animation: moveBar 2.5s infinite;
  animation-delay: 1.6s;
}

@keyframes moveBar {
  0% {
    left: -100%;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
}
